/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ubo
import { LossePlaatjie, useLoaderData, LosseLink, type LoaderData, useLosseLanguage } from '@ubo/losse-sjedel'

// Third party
import clsx from 'clsx'

// Types
import type { PropsWithChildren } from 'react'
import type { Component_Footer } from '~/graphql/types'

// Components
import Content from '../elements/Content'

// SVG
import ExternalURL from '../elements/svg/ExternalURL'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const [, locale] = useLosseLanguage()
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer data-component="Footer" id="footer" className="snap-none bg-ubo-roggenrola text-ubo-delibird">
      <div className="mx-auto grid max-w-8xl grid-cols-4 gap-x-16 gap-y-6 px-4 py-8 sm:py-28 md:px-8 lg:px-16 xl:px-24">
        <div className="col-span-4 sm:col-span-2 xl:col-span-1">
          <Title>Contact</Title>
          <ul className="mt-4">
            {footer?.contactFooter?.contactdata?.map((edge, index) => (
              <li className="mb-4" key={index}>
                <LosseLink className="group flex items-center" to={edge?.link?.url} target={edge?.link?.target || '_self'}>
                  <LossePlaatjie src={edge?.icon} className="transition-transform group-hover:rotate-45" maxwidth={40} />
                  <span className="ml-4">{edge?.link?.title}</span>
                </LosseLink>
              </li>
            ))}
          </ul>
          <ul className="flex">
            {footer.contactFooter?.socials?.map((edge, index) => (
              <li key={index} className="mr-4">
                <LosseLink to={edge?.link?.url} target={edge?.link?.target || '_self'} className="group">
                  <LossePlaatjie src={edge?.icon} className="transition-transform group-hover:scale-95" maxwidth={85} />
                </LosseLink>
              </li>
            ))}
          </ul>
          <LossePlaatjie src={footer?.contactFooter?.image} className="mt-4 h-auto w-[100px] lg:mt-8" />
        </div>
        <div className="col-span-4 sm:col-span-2 xl:col-span-1">
          <Title>Menu</Title>
          <ul className="mt-4">
            {footer.menuFooter?.map((edge, index) => (
              <li key={index} className="pb-1">
                <LosseLink to={edge?.link?.url} target={edge?.link?.target || '_self'}>
                  {edge?.link?.title}
                </LosseLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-4 sm:col-span-2 xl:col-span-1">
          <Title>Support</Title>
          <ul className="mt-4">
            {footer.supportFooter?.map((edge, index) => {
              const odd = index % 2 === 0

              return (
                <li key={index} className="mb-3">
                  <LosseLink className="group inline-flex items-center" to={edge?.link?.url} target={edge?.link?.target || '_self'}>
                    <LossePlaatjie src={edge?.icon} className="transition-transform group-hover:rotate-45" maxwidth={40} />
                    <span className={clsx(odd && 'border-[1px] border-ubo-delibird px-1 py-[2px]', 'ml-4')}>{edge?.link?.title}</span>
                  </LosseLink>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="col-span-4 sm:col-span-2 xl:col-span-1">
          <Title>{locale === 'nl_NL' ? 'Adres' : 'Address'}</Title>
          <div className="group mt-4 children:inline">
            <ExternalURL className="mr-2 transition-transform group-hover:-translate-y-1 group-hover:translate-x-1" />
            <Content className="children:inline children-a:border-0">{footer.addressFooter?.description}</Content>
          </div>
        </div>
      </div>
    </footer>
  )
}

function Title({ children }: { children: PropsWithChildren<string | HTMLSpanElement> }) {
  // @ts-ignore
  return <h2 className="title text-2xl">{children}</h2>
}
