import { memo } from 'react'

function ExternalURL({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none" viewBox="0 0 13 13" className={className}>
      <path
        fill="#F0F0FF"
        d="M.334 11.335a.941.941 0 101.332 1.33l-1.332-1.33zM12.941 1A.941.941 0 0012 .059H3.53a.941.941 0 100 1.882h7.529v7.53a.941.941 0 001.882 0V1zM1.666 12.665l11-11-1.332-1.33-11 11 1.332 1.33z"
      />
    </svg>
  )
}

export default memo(ExternalURL)
