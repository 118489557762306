// @ubo
import { LosseLink, useLocation, useLosseLanguage } from '@ubo/losse-sjedel'

// Components
import HeaderMenu from './HeaderMenu'

// SVG
import Logo from '~/components/elements/svg/Logo'
import clsx from 'clsx'

export default function HeaderDesktop() {
  const [, locale] = useLosseLanguage()
  const location = useLocation()
  const isTools = location.pathname.startsWith('/tools')

  return (
    <ul className="items-center py-6 selection:text-ubo-whiscash lg:flex">
      <LosseLink
        aria-label="Home"
        className={clsx('transition-transform hover:scale-95', !isTools && 'mr-4 2xl:mr-24')}
        to={locale === 'nl_NL' ? '/' : '/en/'}
      >
        <Logo className="lg:w-[50px] 2xl:w-[94px]" />
      </LosseLink>
      <HeaderMenu />
    </ul>
  )
}
