import { LosseLink, useLosseLanguage } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Maybe } from '~/graphql/types'

export default function LangSwitch({ type = 'normal' }: { type?: 'normal' | 'alt' }) {
  const [translations, currentLocale] = useLosseLanguage()
  const translation = translations.find((_translation) => _translation.language.default_locale !== currentLocale) || {
    href: '/',
    language: {
      default_locale: 'nl_NL'
    }
  }

  function sanitizeUrl(url?: Maybe<string>) {
    return url?.replace('home/', '')?.replace('sample-page/', '')
  }

  return (
    <div className="flex justify-center rounded-full bg-white px-5 py-2">
      <LosseLink to={sanitizeUrl(translation?.href)} className="flex items-center text-sm xl:text-base">
        <span
          className={clsx(
            currentLocale === 'nl_NL' ? 'font-semibold' : 'opacity-50 group-hover:underline group-hover:opacity-100',
            'max-lg:text-lg',
            type === 'normal' && 'mr-2',
            type === 'alt' && 'mr-4'
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="12"
            fill="none"
            viewBox="0 0 36 24"
            className={clsx(type === 'normal' && 'h-[16px] w-[24px]', type === 'alt' && 'h-[24px] w-[36px]', 'overflow-hidden rounded')}
          >
            <g clipPath="url(#a)">
              <path fill="#F0F0F0" d="M36 0H0v23.996h36V0Z" />
              <path fill="#0052B4" d="M36 15.997H0v7.999h36v-7.999Z" />
              <path fill="#FF4B55" d="M36 0H0V8h36V0Z" />
            </g>
          </svg>
        </span>
        <label className="relative inline-flex cursor-pointer items-center">
          <input type="checkbox" value="" className="peer sr-only" />
          <div
            className={clsx(
              'peer h-7 w-12 rounded-full border-gray-600 bg-ubo-taillow/25 after:absolute after:top-[2px] after:h-6 after:w-6 after:rounded-full after:border after:border-ubo-taillow/50 after:bg-ubo-whiscash after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-ubo-muk peer-focus:outline-none peer-focus:ring-2 lg:h-6 lg:w-11 lg:after:h-5 lg:after:w-5',
              currentLocale === 'nl_NL' ? 'after:left-[2px]' : 'after:right-[2px]'
            )}
          ></div>
        </label>
        <span
          className={clsx(
            currentLocale === 'nl_NL' ? 'opacity-75 group-hover:underline group-hover:opacity-100' : 'font-semibold',
            'max-lg:text-lg',
            type === 'normal' && 'ml-2',
            type === 'alt' && 'ml-4'
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="12"
            fill="none"
            viewBox="0 0 36 24"
            className={clsx(type === 'normal' && 'h-[16px] w-[24px]', type === 'alt' && 'h-[24px] w-[36px]', 'overflow-hidden rounded')}
          >
            <g clipPath="url(#a)">
              <path fill="#0052B4" d="M36 0H0v23.996h36V0Z" />
              <path
                fill="#F0F0F0"
                fillRule="evenodd"
                d="M36 0v2.5l-9.052 6.033-2.08 1.386-3.119 2.079 3.12 2.08L36 21.496v2.499h-3.75l-10.784-7.188-1.386-.925L18 14.497l-2.08 1.386-1.386.925-10.785 7.188H0v-2.5l9.052-6.033 2.08-1.386 3.119-2.079-3.12-2.08-2.079-1.385L0 2.499V0h3.75l10.784 7.188 1.386.925L18 9.499l2.08-1.386 1.386-.925L32.251 0H36Z"
                clipRule="evenodd"
              />
              <path
                fill="#FF4B55"
                fillRule="evenodd"
                d="m23.618 9.919-3.12 2.079 3.12 2.08 2.08 1.385L36 22.331v1.665h-2.498l-12.036-8.023-1.386-.924L18 13.663l-2.08 1.386-1.386.924-12.036 8.023H0v-1.665l10.303-6.868 2.08-1.386 3.119-2.079-3.12-2.08-2.079-1.385L0 1.665V0h2.498l12.036 8.023 1.386.924L18 10.333l2.08-1.386 1.386-.924L33.502 0H36v1.665L25.697 8.533l-2.08 1.386Z"
                clipRule="evenodd"
              />
              <path
                fill="#F0F0F0"
                fillRule="evenodd"
                d="M21.466 15.464v8.532h-6.932v-8.532H0V8.533h14.534V0h6.932v8.533H36v6.93H21.466Z"
                clipRule="evenodd"
              />
              <path
                fill="#FF4B55"
                fillRule="evenodd"
                d="M20.08 14.077v9.919h-4.16v-9.919H0V9.92h15.92V0h4.16V9.92H36v4.158H20.08Z"
                clipRule="evenodd"
              />
            </g>
          </svg>
        </span>
      </LosseLink>
    </div>
  )
}
