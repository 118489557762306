export default function StrokeBanner({ className }: { className?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="1705" height="293" fill="none" viewBox="0 0 1705 293">
      <mask id="mask0_1984_20422" style={{ maskType: 'alpha' }} width="341" height="284" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#070744" d="M0 0H341V284H0z"></path>
      </mask>
      <g fill="#070744" fillRule="evenodd" clipRule="evenodd" mask="url(#mask0_1984_20422)">
        <path d="M30.006-130.796l-528.041 528.041-1-1L29.006-131.796l1 1zM66.392-124.275l-514.999 514.999-1-1 515-515 1 1.001zM135.725-150.701L-432.126 417.15l-1-1 567.851-567.851 1 1zM211.93-184l-634.449 634.449-1.001-1L210.929-185l1.001 1zM221.532-150.695l-567.839 567.84-1.001-1 567.84-567.84 1 1zM236.349-122.605L-275.31 389.054l-1.001-1 511.66-511.659 1 1zM302.838-146.186l-558.822 558.822-1-1 558.822-558.822 1 1zM320.972-121.414l-566.775 566.775-1-1.001 566.775-566.774 1 1zM389.832-147.365L-241.794 484.26l-1-1 631.625-631.625 1.001 1zM415.078-129.705l-626.931 626.931-1-1 626.93-626.931 1.001 1zM464.973-136.695l-644.822 644.822-1.001-1 644.822-644.822 1.001 1zM495.908-124.722l-633.971 633.971-1-1 633.971-633.971 1 1zM534.901-120.808L-71.581 485.674l-1-1L533.9-121.808l1 1zM591.671-134.671L-46.828 503.827l-1-1L590.67-135.671l1.001 1zM641.568-141.661L-3.684 503.591l-1-1 645.251-645.252 1.001 1zM650.933-108.12L46.059 496.754l-1-1L649.932-109.12l1.001 1zM712.444-126.723L100.989 484.731l-1-1.001 611.454-611.454 1.001 1.001zM807.753-179.126l-675.881 675.88-1-1 675.88-675.88 1.001 1zM841.3-169.766L155.447 516.086l-1-1L840.3-170.766l1 1zM870.818-156.377L291.614 422.826l-1-1 579.204-579.203 1 1z"></path>
      </g>
      <mask id="mask1_1984_20422" style={{ maskType: 'alpha' }} width="341" height="284" x="341" y="2" maskUnits="userSpaceOnUse">
        <path fill="#070744" d="M341 2H682V286H341z"></path>
      </mask>
      <g fill="#070744" fillRule="evenodd" clipRule="evenodd" mask="url(#mask1_1984_20422)">
        <path d="M371.006-128.796l-528.041 528.041-1-1 528.041-528.041 1 1zM407.392-122.275l-514.999 514.999-1-1 514.999-515 1 1.001zM476.725-148.701L-91.125 419.15l-1.001-1 567.851-567.851 1 1zM552.93-182L-81.52 452.449l-1-1L551.93-183l1.001 1zM562.532-148.695l-567.84 567.84-1-1 567.84-567.84 1 1zM577.349-120.605L65.689 391.054l-1-1 511.66-511.659 1 1zM643.838-144.186L85.016 414.636l-1-1 558.822-558.822 1 1zM661.972-119.414L95.198 447.361l-1-1.001 566.774-566.774 1 1zM730.832-145.365L99.206 486.26l-1-1 631.625-631.625 1.001 1zM756.078-127.705L129.147 499.226l-1-1 626.93-626.931 1.001 1zM805.973-134.695L161.151 510.127l-1.001-1 644.822-644.822 1.001 1zM836.908-122.722L202.937 511.249l-1-1 633.971-633.971 1 1zM875.901-118.808L269.419 487.674l-1-1 606.482-606.482 1 1zM932.671-132.671L294.172 505.827l-1-1L931.67-133.671l1.001 1zM982.568-139.661L337.316 505.591l-1.001-1 645.252-645.252 1.001 1zM991.933-106.12L387.059 498.754l-1-1L990.932-107.12l1.001 1zM1053.44-124.723L441.989 486.731l-1-1.001 611.451-611.454 1 1.001zM1148.75-177.126l-675.878 675.88-1-1 675.878-675.88 1 1zM1182.3-167.766L496.447 518.086l-1-1L1181.3-168.766l1 1zM1211.82-154.377L632.614 424.826l-1-1 579.206-579.203 1 1z"></path>
      </g>
      <mask id="mask2_1984_20422" style={{ maskType: 'alpha' }} width="341" height="285" x="682" y="4" maskUnits="userSpaceOnUse">
        <path fill="#070744" d="M682 4.3H1023V288.3H682z"></path>
      </mask>
      <g fill="#070744" fillRule="evenodd" clipRule="evenodd" mask="url(#mask2_1984_20422)">
        <path d="M712.006-126.496L183.965 401.545l-1-1 528.041-528.041 1 1zM748.392-119.975L233.393 395.024l-1-1 514.999-515 1 1.001zM817.725-146.401L249.874 421.45l-1-1 567.851-567.851 1 1zM893.93-179.7L259.481 454.749l-1.001-1L892.929-180.7l1.001 1zM903.532-146.395l-567.839 567.84-1.001-1.001 567.84-567.839 1 1zM918.349-118.305L406.69 393.354l-1.001-1 511.66-511.659 1 1zM984.838-141.886L426.016 416.936l-1-1 558.822-558.822 1 1zM1002.97-117.114L436.197 449.66l-1-1 566.773-566.775 1 1.001zM1071.83-143.065L440.206 488.56l-1-1 631.624-631.626 1 1.001zM1097.08-125.405L470.147 501.526l-1-1.001 626.933-626.93 1 1zM1146.97-132.395L502.151 512.427l-1.001-1 644.82-644.822 1 1zM1177.91-120.422L543.937 513.549l-1-1 633.973-633.971 1 1zM1216.9-116.508L610.419 489.974l-1-1L1215.9-117.509l1 1.001zM1273.67-130.371L635.172 508.127l-1-1 638.498-638.499 1 1.001zM1323.57-137.361L678.316 507.891l-1.001-1 645.255-645.252 1 1zM1332.93-103.82L728.059 501.054l-1-1L1331.93-104.82l1 1zM1394.44-122.424L782.989 489.031l-1-1.001 611.451-611.454 1 1zM1489.75-174.826l-675.878 675.88-1-1 675.878-675.881 1 1.001zM1523.3-165.466L837.447 520.386l-1-1L1522.3-166.467l1 1.001zM1552.82-152.077L973.614 427.126l-1-1 579.206-579.203 1 1z"></path>
      </g>
      <mask id="mask3_1984_20422" style={{ maskType: 'alpha' }} width="341" height="285" x="1023" y="6" maskUnits="userSpaceOnUse">
        <path fill="#070744" d="M1023 6.3H1364V290.3H1023z"></path>
      </mask>
      <g fill="#070744" fillRule="evenodd" clipRule="evenodd" mask="url(#mask3_1984_20422)">
        <path d="M1053.01-124.496L524.965 403.545l-1-1 528.045-528.041 1 1zM1089.39-117.975L574.393 397.024l-1-1 514.997-515 1 1.001zM1158.72-144.401L590.874 423.45l-1-1 567.846-567.851 1 1zM1234.93-177.7L600.481 456.749l-1.001-1L1233.93-178.7l1 1zM1244.53-144.395l-567.837 567.84-1.001-1.001 567.838-567.839 1 1zM1259.35-116.305L747.69 395.354l-1.001-1 511.661-511.659 1 1zM1325.84-139.886L767.016 418.936l-1-1 558.824-558.822 1 1zM1343.97-115.114L777.197 451.66l-1-1 566.773-566.775 1 1.001zM1412.83-141.065L781.206 490.561l-1-1 631.624-631.626 1 1zM1438.08-123.404L811.147 503.527l-1-1.001 626.933-626.93 1 1zM1487.97-130.394L843.151 514.428l-1.001-1 644.82-644.822 1 1zM1518.91-118.421L884.937 515.55l-1-1 633.973-633.971 1 1zM1557.9-114.507L951.419 491.975l-1-1L1556.9-115.508l1 1.001zM1614.67-128.371L976.172 510.127l-1-1 638.498-638.499 1 1.001zM1664.57-135.36l-645.25 645.252-1-1 645.25-645.252 1 1zM1673.93-101.819l-604.87 604.874-1-1 604.87-604.874 1 1zM1735.44-120.423l-611.45 611.454-1-1 611.45-611.454 1 1zM1830.75-172.825l-675.88 675.88-1-1 675.88-675.881 1 1.001zM1864.3-163.465l-685.85 685.852-1-1 685.85-685.853 1 1.001zM1893.82-150.077l-579.21 579.203-1-1 579.21-579.203 1 1z"></path>
      </g>
      <mask id="mask4_1984_20422" style={{ maskType: 'alpha' }} width="341" height="285" x="1364" y="8" maskUnits="userSpaceOnUse">
        <path fill="#070744" d="M1364 8.5H1705V292.5H1364z"></path>
      </mask>
      <g fill="#070744" fillRule="evenodd" clipRule="evenodd" mask="url(#mask4_1984_20422)">
        <path d="M1394.01-122.296L865.965 405.745l-1-1 528.045-528.041 1 1zM1430.39-115.775L915.393 399.224l-1-1 514.997-515 1 1.001zM1499.72-142.201L931.874 425.65l-1-1 567.846-567.851 1 1zM1575.93-175.5L941.481 458.949l-1.001-1L1574.93-176.5l1 1zM1585.53-142.195l-567.84 567.84-1-1 567.84-567.84 1 1zM1600.35-114.105l-511.66 511.659-1-1 511.66-511.659 1 1zM1666.84-137.686l-558.82 558.822-1-1 558.82-558.822 1 1zM1684.97-112.914L1118.2 453.861l-1-1.001 566.77-566.774 1 1zM1753.83-138.865L1122.21 492.76l-1-1 631.62-631.625 1 1zM1779.08-121.204l-626.93 626.931-1-1 626.93-626.931 1 1zM1828.97-128.195l-644.82 644.822-1-1 644.82-644.822 1 1zM1859.91-116.221L1225.94 517.75l-1-1 633.97-633.971 1 1zM1898.9-112.308l-606.48 606.482-1-1 606.48-606.482 1 1zM1955.67-126.172l-638.5 638.498-1-1 638.5-638.498 1 1zM2005.57-133.16l-645.25 645.252-1-1 645.25-645.252 1 1zM2014.93-99.62l-604.87 604.874-1-1 604.87-604.874 1 1zM2076.44-118.223l-611.45 611.454-1-1.001 611.45-611.454 1 1.001zM2171.75-170.626l-675.88 675.88-1-1 675.88-675.88 1 1zM2205.3-161.266l-685.85 685.852-1-1 685.85-685.852 1 1zM2234.82-147.877l-579.21 579.203-1-1 579.21-579.203 1 1z"></path>
      </g>
    </svg>
  )
}
