import { memo } from 'react'
import useLayout from '~/hooks/useLayout'

function Corner({ className = '' }: { className?: string }) {
  const { navigationBgColor } = useLayout()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 18 18" className={className}>
      <path fill={navigationBgColor === 'transparent' ? '#FFFFFF' : '#000CED'} d="M.377 3.893V0H18v17.693h-3.878v-13.8H.377z" />
    </svg>
  )
}

export default memo(Corner)
