import type { FC } from 'react'
import { LosseLink } from '@ubo/losse-sjedel'

export interface ButtonProps extends React.ComponentPropsWithoutRef<any> {
  onClick?: () => void
  to?: string
}

const Button: FC<ButtonProps> = ({ children, className = '', ...props }) => {
  return (
    <LosseLink className={`${className} bg-indigo-800 py-2 px-3 text-white hover:text-white`} {...props}>
      {children}
    </LosseLink>
  )
}

export default Button
