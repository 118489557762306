/* eslint-disable @typescript-eslint/ban-ts-comment */
// Third party
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

// Hooks
import useLayout from '~/hooks/useLayout'

// Components
import HeaderMenu from './HeaderMenu'

// SVG
import StrokeBanner from '~/components/elements/svg/StrokeBanner'

// Hooks
import { useEffect, useState } from 'react'
import Logo from '~/components/elements/svg/Logo'
import { LosseLink, useLocation, useLosseLanguage } from '@ubo/losse-sjedel'
import useClientMediaQuery from '~/hooks/useClientMediaQuery'

export default function HeaderSticky() {
  const { setStickyMenuOpen, stickyMenuOpen } = useLayout()

  return (
    <>
      <HeaderStickyDesktop />
      <HeaderStickyMobile />
      <AnimatePresence>
        {stickyMenuOpen && (
          <motion.nav
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '100%', opacity: 0 }}
            transition={{
              bounce: 0
            }}
            className="fixed left-0 right-0 top-0 z-[2000] flex h-screen w-screen flex-col bg-ubo-whiscash text-ubo-delibird"
          >
            <div className="absolute -top-4 left-0 flex  h-[220px] w-auto sm:h-[250px] md:h-auto">
              <StrokeBanner />
              <StrokeBanner className="relative top-[11px] hidden xl:block" />
            </div>
            <ul className="relative mx-auto mt-auto flex w-full flex-col gap-y-4 pb-64 md:gap-y-8 xl:max-w-screen-lg 2xl:pb-96">
              <button
                type="button"
                className="fixed bottom-[10vh] left-0 right-0 m-auto flex h-10 w-10 items-center justify-center lg:bottom-[unset] lg:left-[unset] lg:right-5 lg:top-5"
                onClick={() => {
                  setStickyMenuOpen(!stickyMenuOpen)
                }}
                aria-label="Close menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="h-full w-full"
                >
                  <path d="M18 6L6 18"></path>
                  <path d="M6 6L18 18"></path>
                </svg>
              </button>
              <HeaderMenu />
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  )
}

function HeaderStickyDesktop() {
  const [, locale] = useLosseLanguage()
  const [hovered, setHovered] = useState(false)
  const { setStickyMenuOpen, stickyMenuOpen, scrollIndicator } = useLayout()
  const [scrollIndicatorFields, setScrollIndicatorFields] = useState<
    { title: string | null; scrollTop: number | null; scrollEnd: number | null; section: HTMLElement }[]
  >([])
  const [scrollOffset, setScrollOffset] = useState(0)
  const isDesktop = useClientMediaQuery('(min-width: 1024px)')

  function calculatePercentage(start: number, end: number, value: number) {
    const percentage = ((value - start) / (end - start)) * 100
    return percentage
  }

  useEffect(() => {
    const sections = Array.from(document.querySelectorAll('section'))
    const sectionsWithScrollIndicator = sections.filter((edge) => edge.getAttribute('data-scrollindicatortitle'))

    setScrollIndicatorFields(
      sectionsWithScrollIndicator.map((edge, i) => {
        const { top } = edge.getBoundingClientRect()
        const scrollTop = window.scrollY + top - 1

        let nextSectionScrollTop = 0
        const nextSection = sectionsWithScrollIndicator[i + 1]

        if (nextSection !== undefined) {
          nextSectionScrollTop = nextSection.getBoundingClientRect().top
        }

        return {
          title: edge.getAttribute('data-scrollindicatortitle'),
          scrollTop: scrollTop,
          scrollEnd: window.scrollY + nextSectionScrollTop - 1.01,
          section: edge
        }
      })
    )
  }, [])

  const handleScroll = () => {
    const currentScrollOffset = window.pageYOffset || document.documentElement.scrollTop
    setScrollOffset(currentScrollOffset)
  }

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const { pathname } = useLocation()

  const pathsWithoutNav = ['grow-business-leadgeneratie']

  return (
    <div className="fixed left-0 right-0 top-0 z-[100] w-full">
      <div className={clsx(scrollIndicator ? 'justify-between' : 'justify-end', 'relative mx-auto flex w-full max-w-[1660px]')}>
        <AnimatePresence>
          {scrollIndicator && (
            <motion.div
              initial={isDesktop && { y: 30 }}
              animate={isDesktop && { y: 0 }}
              // @ts-ignore
              exit={isDesktop && { y: 30 }}
              className="container mt-4"
            >
              <div className="fixed flex w-[98%] flex-wrap items-center justify-center rounded-3xl bg-ubo-whiscash px-2 py-1 text-white shadow-md max-lg:bottom-1 max-lg:left-1 max-lg:hidden max-lg:overflow-auto lg:relative lg:w-fit lg:max-w-[90%] lg:rounded-full lg:px-10 lg:py-4">
                <div className="relative top-1 mr-14 hidden xl:block">
                  <span className="text-xl font-semibold lg:text-5xl">{locale === 'nl_NL' ? 'INHOUD' : 'CONTENTS'}</span>
                </div>
                {scrollIndicatorFields.map((edge, i) => {
                  // @ts-ignore
                  const intersected = scrollOffset > edge.scrollTop
                  // @ts-ignore
                  const prctg = calculatePercentage(edge.scrollTop, edge.scrollEnd, scrollOffset)

                  return (
                    <div
                      role="button"
                      onClick={() => edge.section.scrollIntoView()}
                      key={edge.title}
                      className="group relative flex flex-col items-start pr-4 lg:pr-14"
                    >
                      <span className="relative inline-block text-[10px] group-hover:underline lg:text-lg lg:font-semibold">
                        {edge.title}
                      </span>
                      <div className="flex flex-col justify-center">
                        <div
                          className={clsx(
                            intersected ? 'bg-white' : 'bg-transparent',
                            'h-2 w-2 rounded-full border border-white transition-colors lg:h-3 lg:w-3 lg:border-2'
                          )}
                        />
                        {i !== scrollIndicatorFields.length - 1 && (
                          <div
                            className="absolute bottom-[3px] h-[2px] max-w-full bg-white lg:bottom-1 lg:h-1"
                            style={{ width: prctg < 0 ? 0 : `${prctg}%` }}
                          ></div>
                        )}
                        {i === 0 && <div className="absolute bottom-[3px] right-full h-[2px] w-1/5 bg-white lg:bottom-1 lg:h-1"></div>}
                      </div>
                    </div>
                  )
                })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div
          role="button"
          onClick={() => {
            setStickyMenuOpen(!stickyMenuOpen)
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={clsx(
            hovered ? 'pb-3 pl-6 pr-6 pt-0' : 'pb-[0.125rem] pl-8 pr-4 pt-[0.125rem]',
            scrollIndicator ? 'top-8' : ' top-4',
            pathsWithoutNav.includes(pathname.replace(/\//g, '')) && 'pointer-events-none opacity-0',
            'glass absolute right-10 hidden rounded-full bg-ubo-taillow text-center text-2xl font-bold text-white transition-all lg:block'
          )}
          aria-label="Open menu"
        >
          <span className="relative !leading-[1.5]">
            <motion.span
              className="absolute block h-1 bg-ubo-delibird"
              animate={
                hovered ? { rotate: 0, width: '100%', y: '2.25rem', x: '0rem' } : { rotate: 110, width: '1.75rem', y: '1rem', x: '-1.5rem' }
              }
              transition={{
                bounce: 0.15
              }}
            />
            menu
          </span>
        </div>
      </div>
    </div>
  )
}

function HeaderStickyMobile() {
  const [, locale] = useLosseLanguage()
  const { setStickyMenuOpen, stickyMenuOpen, navigationBgColor, sticky } = useLayout()
  const { pathname } = useLocation()
  const pathsWithoutNav = ['grow-business-leadgeneratie']

  return (
    <div
      className={clsx(
        navigationBgColor === 'transparent' && !sticky ? 'bg-transparent' : 'bg-white',
        sticky && 'shadow-md',
        'header-sticky-mobile fixed left-0 right-0 top-0 z-[99]  flex items-center justify-between px-4 py-5 lg:hidden'
      )}
    >
      <LosseLink aria-label="Home" className="mr-4 transition-transform hover:scale-95" to={locale === 'nl_NL' ? '/' : '/en/'}>
        <Logo className="h-9 w-20" />
      </LosseLink>
      <div
        role="button"
        onClick={() => {
          setStickyMenuOpen(!stickyMenuOpen)
        }}
        className={clsx(
          navigationBgColor === 'transparent' && !sticky ? 'text-ubo-delibird' : 'text-ubo-whiscash',
          pathsWithoutNav.includes(pathname.replace(/\//g, '')) && 'pointer-events-none opacity-0',
          'z-[100] block px-3 py-1 text-center text-xl font-bold'
        )}
        aria-label="Open menu"
      >
        <span className="relative">
          <span
            className={clsx(
              navigationBgColor === 'transparent' && !sticky ? 'bg-ubo-delibird' : 'bg-ubo-whiscash',
              'absolute left-[-18px] top-[10px] block h-[0.2rem] w-5 rotate-[110deg]'
            )}
          />
          menu
        </span>
      </div>
    </div>
  )
}
