/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ubo
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLayout, useLoaderData, useLocation } from '@ubo/losse-sjedel'

// Third party
import { AnimatePresence, motion } from 'framer-motion'

// Types
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'

// Hooks
import { useEffect, useState, createContext } from 'react'
import useClientMediaQuery from '~/hooks/useClientMediaQuery'

// Components
import Footer from './Footer'
import Header from './Header'
import HeaderSticky from './Header/HeaderSticky'
import LogoLight from '../elements/svg/LogoLight'

interface LayoutProps {
  scrollEffect: boolean
  setScrollEffect: Dispatch<SetStateAction<boolean>>
  stickyMenuOpen: boolean
  setStickyMenuOpen: Dispatch<SetStateAction<boolean>>
  splashSeen: boolean
  setSplashSeen: Dispatch<SetStateAction<boolean>>
  sticky: boolean
  setSticky: Dispatch<SetStateAction<boolean>>
  smallWindow: boolean
  navigationBgColor: string
  scrollIndicator: boolean
}

export const LayoutContext = createContext<LayoutProps>({
  scrollEffect: false,
  setScrollEffect: (boolean) => boolean,
  stickyMenuOpen: false,
  setStickyMenuOpen: (boolean) => boolean,
  splashSeen: false,
  setSplashSeen: (boolean) => boolean,
  sticky: false,
  setSticky: (boolean) => boolean,
  smallWindow: true,
  navigationBgColor: 'white',
  scrollIndicator: false
})

export default function Layout({ children }: PropsWithChildren<{}>) {
  const { page } = useLoaderData<LoaderData>()
  const [scrollEffect, setScrollEffect] = useState(false)
  const [stickyMenuOpen, setStickyMenuOpen] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [splashSeen, setSplashSeen] = useState(true)
  const { pathname } = useLocation()

  const smallWindow = useClientMediaQuery('(max-width: 1023px)')

  useEffect(() => {
    if (pathname !== '/') {
      setTimeout(() => {
        setScrollEffect(false)
      }, 1000)
    }
    setStickyMenuOpen(false)
  }, [pathname])

  useEffect(() => {
    const { documentElement: html } = document
    if (stickyMenuOpen) {
      html.classList.add('overflow-hidden')
    } else {
      html.classList.remove('overflow-hidden')
    }
  }, [stickyMenuOpen])

  useEffect(() => {
    const { documentElement: html } = document

    if (scrollEffect && splashSeen && !smallWindow) {
      html?.classList.add('snap-y', 'snap-proximity')
    } else {
      html?.classList.remove('snap-y', 'snap-proximity')
    }
  }, [smallWindow, scrollEffect, splashSeen, sticky])

  useEffect(() => {
    function handleScroll() {
      setSticky(window.scrollY > 106)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  // ▄▀▀____▀▀▀▀▄____█
  // ___________▄▀▀__▀▀▀▀▀▀▄___▀▄___█
  // __________█▄▄▄▄▄▄_______▀▄__▀▄__█
  // _________█_________▀▄______█____█_█
  // ______▄█_____________▀▄_____▐___▐_▌
  // ______██_______________▀▄___▐_▄▀▀▀▄
  // ______█________██_______▌__▐▄▀______█
  // ______█_________█_______▌__▐▐________▐
  // _____▐__________▌_____▄▀▀▀__▌_______▐_____________▄▄▄▄▄▄
  // ______▌__________▀▀▀▀________▀▀▄▄▄▀______▄▄████▓▓▓▓▓▓▓███▄
  // ______▌____________________________▄▀__▄▄█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄
  // ______▐__________________________▄▀_▄█▓▓▓▓▓▓▓▓▓▓_____▓▓____▓▓█▄
  // _______▌______________________▄▀_▄█▓▓▓▓▓▓▓▓▓▓▓____▓▓_▓▓_▓▓__▓▓█
  // _____▄▀▄_________________▄▀▀▌██▓▓▓▓▓▓▓▓▓▓▓▓▓__▓▓▓___▓▓_▓▓__▓▓█
  // ____▌____▀▀▀▄▄▄▄▄▄▄▄▀▀___▌█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓__▓________▓▓___▓▓▓█
  // _____▀▄_________________▄▀▀▓▓▓▓▓▓▓▓█████████████▄▄_____▓▓__▓▓▓█
  // _______█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▓▓▓▓▓██▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓██▄▄___▓▓▓▓▓█
  // _______█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▓▓███▓▓▓▓████▓▓▓▓▓▓▓▓▓▓▓▓▓██▓▓▓▓▓▓█
  // ________█▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▓█▓▓██░░███████░██▓▓▓▓▓▓▓▓▓▓██▓▓▓▓▓█
  // ________█▓▓▓▓▓▓▓▓▓▓▓▓▓▓██▓░░░░░█░░░░░██░░░░██▓▓▓▓▓▓▓▓▓██▓▓▓▓▌
  // ________█▓▓▓▓▓▓▓▓▓▓▓▓▓▓███░░░░░░░░____░██░░░░░░░██▓▓▓▓▓▓▓██▓▓▌
  // ________▐▓▓▓▓▓▓▓▓▓▓▓▓▓▓██░░░░░░░________░░░░░░░░░██████▓▓▓▓▓█▓▌
  // ________▐▓▓▓▓▓▓▓▓▓▓▓▓▓▓██░░░░░░___▓▓▓▓▓░░░░░░░███░░███▓▓▓▓▓█▓▌
  // _________█▓▓▓▓▓▓▓▓▓▓▓▓▓██░░░░░___▓▓█▄▄▓░░░░░░░░___░░░░█▓▓▓▓▓█▓▌
  // _________█▓▓▓▓▓▓▓▓▓▓▓▓▓█░░█░░░___▓▓██░░░░░░░░▓▓▓▓__░░░░█▓▓▓▓██
  // _________█▓▓▓▓▓▓▓▓▓▓▓▓▓█░███░░____▓░░░░░░░░░░░█▄█▓__░░░░█▓▓█▓█
  // _________▐▓▓▓▓▓▓▓▓▓▓▓▓▓█░█████░░░░░░░░░░░░░░░░░█▓__░░░░███▓█
  // __________█▓▓▓▓▓▓▓▓▓▓▓▓█░░███████░░░░░░░░░░░░░░░▓_░░░░░██▓█
  // __________█▓▓▓▓▓▓▓▓▓▓▓▓█░░░███████░░░░░░░░░░░░░░_░░░░░██▓█
  // __________█▓▓▓▓▓▓▓▓▓▓▓▓█░░░███████░░░░░░░░░░░░░░░░░░░██▓█
  // ___________█▓▓▓▓▓▓▓▓▓▓▓▓█░░░░███████░░░░░░░░░░░█████░██░░░
  // ___________█▓▓▓▓▓▓▓▓▓▓▓▓█░░░░░░__███████░░░░░███████░░█░░░░
  // ___________█▓▓▓▓▓▓▓▓▓▓▓▓█░░░░░░█▄▄▄▀▀▀▀████████████░░█░░░░
  // ___________▐▓▓▓▓▓▓▓▓▓▓▓▓█░░░░░░██████▄__▀▀░░░███░░░░░█░░░
  // ___________▐▓▓▓▓▓▓▓▓▓▓▓█▒█░░░░░░▓▓▓▓▓███▄░░░░░░░░░░░░░░░______▄▄▄
  // ___________█▓▓▓▓▓▓▓▓▓█▒▒▒▒█░░░░░░▓▓▓▓▓█░░░░░░░░░░░░░░░▄▄▄_▄▀▀____▀▄
  // __________█▓▓▓▓▓▓▓▓▓█▒▒▒▒█▓▓░░░░░░░░░░░░░░░░░░░░░____▄▀____▀▄_________▀▄
  // _________█▓▓▓▓▓▓▓▓▓█▒▒▒▒█▓▓▓▓░░░░░░░░░░░░░░░░░______▐▄________█▄▄▀▀▀▄__█
  // ________█▓▓▓▓▓▓▓▓█▒▒▒▒▒▒█▓▓▓▓▓▓▓░░░░░░░░░____________█_█______▐_________▀▄▌
  // _______█▓▓▓▓▓▓▓▓█▒▒▒▒▒▒███▓▓▓▓▓▓▓▓▓▓▓█▒▒▄___________█__▀▄____█____▄▄▄____▐
  // ______█▓▓▓▓▓▓▓█_______▒▒█▒▒██▓▓▓▓▓▓▓▓▓▓█▒▒▒▄_________█____▀▀█▀▄▀▀▀___▀▀▄▄▐
  // _____█▓▓▓▓▓██▒_________▒█▒▒▒▒▒███▓▓▓▓▓▓█▒▒▒██________▐_______▀█_____________█
  // ____█▓▓████▒█▒_________▒█▒▒▒▒▒▒▒▒███████▒▒▒▒██_______█_______▐______▄▄▄_____█
  // __█▒██▒▒▒▒▒▒█▒▒____▒▒▒█▒▒▒▒▒▒▒▒▒▒▒▒▒▒█▒____▒█▓█__▄█__█______▀▄▄▀▀____▀▀▄▄█
  // __█▒▒▒▒▒▒▒▒▒▒█▒▒▒████▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█_______█▓▓█▓▓▌_▐________▐____________▐
  // __█▒▒▒▒▒▒▒▒▒▒▒███▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█▒_______█▓▓▓█▓▌__▌_______▐_____▄▄____▐

  return (
    <LosseLayout>
      <LayoutContext.Provider
        value={{
          scrollEffect,
          setScrollEffect,
          stickyMenuOpen,
          setStickyMenuOpen,
          splashSeen,
          setSplashSeen,
          sticky,
          setSticky,
          smallWindow,
          // @ts-ignore
          navigationBgColor: page?.flexOptions?.navigationBgColor ?? 'white',
          // @ts-ignore
          scrollIndicator: page.flexOptions?.scrollIndicator ?? false
        }}
      >
        {/* @ts-ignore */}
        {page?.flexOptions?.navigationBgColor === 'landings_page' ? (
          <>
            <div className="absolute left-0 top-0 z-50 w-full py-8">
              <div className="container flex justify-end">
                {page?.databaseId === 10081 ? (
                  <img src="/img/Carerix-Logo.png" alt="Logo" className="w-48" />
                ) : (
                  <LogoLight />
                )}
              </div>
            </div>
          </>
        ) : (
          <>{sticky ? <HeaderSticky /> : <Header />}</>
        )}
        <AnimatePresence mode="wait" initial={false}>
          <motion.main
            id="main"
            key={pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen"
          >
            {children}
          </motion.main>
        </AnimatePresence>
        {/* @ts-ignore */}
        {page?.flexOptions?.navigationBgColor !== 'landings_page' && <Footer />}
      </LayoutContext.Provider>
    </LosseLayout>
  )
}
