// @ubo
import { type LoaderData } from '@ubo/losse-sjedel'

// Lib
import { AnimatePresence, motion } from 'framer-motion'

// Components
import HeaderDesktop from './HeaderDesktop'
import HeaderSticky from './HeaderSticky'

// Types
import type { Component_Header } from '~/graphql/types'
import useLayout from '~/hooks/useLayout'
import clsx from 'clsx'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { sticky, smallWindow, navigationBgColor } = useLayout()

  return (
    <AnimatePresence mode="wait">
      {sticky || smallWindow ? (
        <HeaderSticky />
      ) : (
        <motion.nav
          key="header"
          data-component="Header"
          id="header"
          className={clsx(
            navigationBgColor === 'transparent' ? 'bg-transparent' : 'bg-white',
            'fixed left-0 right-0 top-0 z-[99] hidden h-auto w-full justify-center px-6 transition-colors lg:flex'
          )}
          initial={{ opacity: 0 }}
          animate={!sticky ? { opacity: 1 } : { opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <HeaderDesktop />
        </motion.nav>
      )}
    </AnimatePresence>
  )
}
