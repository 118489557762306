export default function Arrow({ className, type = 'light' }: { className?: string; type?: 'light' | 'lightBlue' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" fill="inherit" viewBox="0 0 17 11" className={className}>
      <path
        fill={type === 'light' ? '#fff' : 'inherit'}
        fillRule="evenodd"
        d="M11.74.205l4.455 4.455a.7.7 0 010 .99l-4.455 4.454a.7.7 0 11-.99-.99l3.26-3.26H.7a.7.7 0 110-1.4h13.31l-3.26-3.259a.7.7 0 11.99-.99z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
